import moment from 'moment'
import { monthNums } from '@/utilities/Date/constants'

export function mounted() {
  const today = moment()

  this.monthDetails.value = monthNums[today.month() + 1]
  this.yearDetails.value = today.year()

  this.show({
    month: today.month() + 1,
    year: today.year()
  })
}

<template>
  <div
    pleasant-calendar
    role="region"
    aria-label="calendar"
    :style="{ position: 'relative' }"
    class="pleasant-calendar"
  >
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        padding: '8px',

        background: theme[1],
        borderRadius: '4px',
        boxShadow: `0 0 2px 2px ` + theme[3]
      }"
    >
      <b-button @click="prev">
        <b-icon
          aria-label="previous month button"
          class="mdi mdi-chevron-left mdi-24px"
          size="is-small"
        />
      </b-button>

      <div
        :style="{
          display: 'flex',
          ...(['mobile', 'tablet'].includes(breakpoint)
            ? {
                flexDirection: 'column'
              }
            : {})
        }"
      >
        <b-select
          month-select
          class="month-select"
          aria-label="month selection of calendar"
          placeholder="Select a name"
          @input="monthChange"
          v-model="monthDetails.value"
          :style="{
            color: calendarOpacity === 0 ? `rgba(0,0,0,0)` : '',
            transition: `color ${opacityDelay / 1000}s`
          }"
        >
          <option v-for="option in months" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>

        <b-input
          year-input
          aria-label="year input of calendar"
          class="year-input"
          v-model="yearDetails.value"
          @input="yearChange"
          :style="{
            textAlign: 'center',
            width: '180px',
            color: calendarOpacity === 0 ? `rgba(0,0,0,0)` : '',
            transition: `color ${opacityDelay / 1000}s`,
            ...(['mobile', 'tablet'].includes(breakpoint)
              ? {
                  marginTop: '8px'
                }
              : {
                  marginLeft: '8px'
                })
          }"
        />
      </div>

      <b-button @click="next">
        <b-icon
          aria-label="next month button"
          class="mdi mdi-chevron-right mdi-24px"
          size="is-small"
        />
      </b-button>
    </div>

    <div
      :style="{
        marginTop: '20px',
        gridTemplateColumns: 'repeat(7,1fr)',
        ...(['mobile', 'tablet'].includes(breakpoint)
          ? {
              display: 'none'
            }
          : {
              display: 'grid'
            })
      }"
    >
      <div
        v-for="(h, a) in weekHeaders"
        :key="a"
        class="week-headers"
        :style="{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }"
      >
        <div>
          <b>{{ h }}</b>
        </div>
      </div>
    </div>

    <div
      class="days-foundation"
      :style="{
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(7,1fr)',
        opacity: calendarOpacity,
        transition: 'opacity .2s',
        pointerEvents: yearDetails.successful === false ? 'none' : ''
      }"
    >
      <div
        v-for="(d, b) in panels"
        :key="b + 7"
        :style="{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px',
          opacity: d.isPast ? '.3' : 1,
          visibility: d.kind === 'day' ? '' : 'hidden'
        }"
        :class="'day-panel' + (d.kind === 'day' ? '' : ' day-panel-hidden')"
        day-panel
        :tabindex="d.kind === 'day' ? 0 : null"
        :role="d.kind === 'day' ? 'button' : ''"
        :aria-label="getPlace(d.dayNum) + ' of ' + monthStr + ' ' + year"
        @click="dayTapped_(d.dayNum)"
        @keyup.enter="dayTapped_(d.dayNum)"
      >
        <div
          :style="{
            background: theme[1],
            width: '95%',
            height: '95%',
            borderRadius: '4px',
            boxShadow: `0 0 2px 1px ` + theme[3],
            color: theme[5],
            overflowY: 'auto'
          }"
        >
          <div v-if="d.kind === 'day'" :style="{ textAlign: 'center' }">{{ d.dayNum }}</div>

          <p
            v-for="(event, c) in d.events"
            :key="c"
            :style="{
              textAlign: 'center',
              whiteSpace: 'pre-wrap',
              margin: '0px 6px 6px',
              boxShadow: `0 0 2px 1px ${theme[6]}`,
              borderRadius: '4px'
            }"
            @click="eventTapped({ event })"
          >
            {{ event.name }}
          </p>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="
          (typeof externalProblem === 'string' && externalProblem.length >= 1) ||
            yearDetails.successful === false
        "
        class="card"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',

          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',

          boxSizing: 'border-box',
          border: `3px solid ${theme[4]}`,
          width: '240px',
          // height: '100px',
          padding: '12px',

          textAlign: 'center'
        }"
      >
        <div v-if="yearDetails.successful === false" :style="{ padding: '10px 0' }">
          {{ yearDetails.message }}
        </div>

        <div
          v-if="typeof externalProblem === 'string' && externalProblem.length >= 1"
          :style="{ padding: '10px 0' }"
        >
          {{ externalProblem }}
        </div>
      </div>
    </transition>

    <b-loading :is-full-page="false" v-model="loading"></b-loading>
  </div>
</template>

<style lang="scss">
@import '~@/assets/styles/_variables';

.pleasant-calendar {
  .year-input {
    input {
      text-align: center !important;
      color: inherit;
    }
  }

  .month-select {
    select {
      color: inherit;
    }
  }

  @media screen and (max-width: $desktop) {
    .day-panel {
      height: 200px !important;
    }

    .day-panel-hidden {
      display: none !important;
    }

    .days-foundation {
      display: block !important;
    }
  }
}
</style>

<script>
/*
  import PleasantCalendar from '@/components/Calendars/Pleasant'

  <PleasantCalendar 
    ref="calendar"
    :events="[]"
    :dayTapped=""
  />

  this.$refs.calendar.move ({
    month: 12,
    year: 2021
  })

  this.$refs.calendar.curtain (false)
*/
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { props } from './keys/props'
import { watch } from './keys/watch'
//
export default {
  computed,
  data,
  props,
  methods,
  mounted,
  watch
}
</script>

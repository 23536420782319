export function data() {
  return {
    loading: true,
    opacity: 1,
    opacityDelay: 200,
    panels: [],
    month: null,
    monthStr: '',
    year: '',
    weekHeaders: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    monthDetails: {
      value: '',
      message: '',
      successful: null
    },
    yearDetails: {
      value: '',
      message: '',
      successful: null
    }
  }
}

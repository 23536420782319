export const computed = {
  calendarOpacity() {
    if (this.loading === true) {
      return 0
    }

    if (this.yearDetails.successful === false) {
      return '.3'
    }

    return 1
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pleasant-calendar",style:({ position: 'relative' }),attrs:{"pleasant-calendar":"","role":"region","aria-label":"calendar"}},[_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      width: '100%',
      padding: '8px',

      background: _vm.theme[1],
      borderRadius: '4px',
      boxShadow: "0 0 2px 2px " + _vm.theme[3]
    })},[_c('b-button',{on:{"click":_vm.prev}},[_c('b-icon',{staticClass:"mdi mdi-chevron-left mdi-24px",attrs:{"aria-label":"previous month button","size":"is-small"}})],1),_c('div',{style:(Object.assign({}, {display: 'flex'},
        (['mobile', 'tablet'].includes(_vm.breakpoint)
          ? {
              flexDirection: 'column'
            }
          : {})))},[_c('b-select',{staticClass:"month-select",style:({
          color: _vm.calendarOpacity === 0 ? "rgba(0,0,0,0)" : '',
          transition: ("color " + (_vm.opacityDelay / 1000) + "s")
        }),attrs:{"month-select":"","aria-label":"month selection of calendar","placeholder":"Select a name"},on:{"input":_vm.monthChange},model:{value:(_vm.monthDetails.value),callback:function ($$v) {_vm.$set(_vm.monthDetails, "value", $$v)},expression:"monthDetails.value"}},_vm._l((_vm.months),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('b-input',{staticClass:"year-input",style:(Object.assign({}, {textAlign: 'center',
          width: '180px',
          color: _vm.calendarOpacity === 0 ? "rgba(0,0,0,0)" : '',
          transition: ("color " + (_vm.opacityDelay / 1000) + "s")},
          (['mobile', 'tablet'].includes(_vm.breakpoint)
            ? {
                marginTop: '8px'
              }
            : {
                marginLeft: '8px'
              }))),attrs:{"year-input":"","aria-label":"year input of calendar"},on:{"input":_vm.yearChange},model:{value:(_vm.yearDetails.value),callback:function ($$v) {_vm.$set(_vm.yearDetails, "value", $$v)},expression:"yearDetails.value"}})],1),_c('b-button',{on:{"click":_vm.next}},[_c('b-icon',{staticClass:"mdi mdi-chevron-right mdi-24px",attrs:{"aria-label":"next month button","size":"is-small"}})],1)],1),_c('div',{style:(Object.assign({}, {marginTop: '20px',
      gridTemplateColumns: 'repeat(7,1fr)'},
      (['mobile', 'tablet'].includes(_vm.breakpoint)
        ? {
            display: 'none'
          }
        : {
            display: 'grid'
          })))},_vm._l((_vm.weekHeaders),function(h,a){return _c('div',{key:a,staticClass:"week-headers",style:({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      })},[_c('div',[_c('b',[_vm._v(_vm._s(h))])])])}),0),_c('div',{staticClass:"days-foundation",style:({
      position: 'relative',
      marginTop: '4px',
      display: 'grid',
      gridTemplateColumns: 'repeat(7,1fr)',
      opacity: _vm.calendarOpacity,
      transition: 'opacity .2s',
      pointerEvents: _vm.yearDetails.successful === false ? 'none' : ''
    })},_vm._l((_vm.panels),function(d,b){return _c('div',{key:b + 7,class:'day-panel' + (d.kind === 'day' ? '' : ' day-panel-hidden'),style:({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        opacity: d.isPast ? '.3' : 1,
        visibility: d.kind === 'day' ? '' : 'hidden'
      }),attrs:{"day-panel":"","tabindex":d.kind === 'day' ? 0 : null,"role":d.kind === 'day' ? 'button' : '',"aria-label":_vm.getPlace(d.dayNum) + ' of ' + _vm.monthStr + ' ' + _vm.year},on:{"click":function($event){return _vm.dayTapped_(d.dayNum)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.dayTapped_(d.dayNum)}}},[_c('div',{style:({
          background: _vm.theme[1],
          width: '95%',
          height: '95%',
          borderRadius: '4px',
          boxShadow: "0 0 2px 1px " + _vm.theme[3],
          color: _vm.theme[5],
          overflowY: 'auto'
        })},[(d.kind === 'day')?_c('div',{style:({ textAlign: 'center' })},[_vm._v(_vm._s(d.dayNum))]):_vm._e(),_vm._l((d.events),function(event,c){return _c('p',{key:c,style:({
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            margin: '0px 6px 6px',
            boxShadow: ("0 0 2px 1px " + (_vm.theme[6])),
            borderRadius: '4px'
          }),on:{"click":function($event){return _vm.eventTapped({ event: event })}}},[_vm._v(" "+_vm._s(event.name)+" ")])})],2)])}),0),_c('transition',{attrs:{"name":"fade"}},[(
        (typeof _vm.externalProblem === 'string' && _vm.externalProblem.length >= 1) ||
          _vm.yearDetails.successful === false
      )?_c('div',{staticClass:"card",style:({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',

        boxSizing: 'border-box',
        border: ("3px solid " + (_vm.theme[4])),
        width: '240px',
        // height: '100px',
        padding: '12px',

        textAlign: 'center'
      })},[(_vm.yearDetails.successful === false)?_c('div',{style:({ padding: '10px 0' })},[_vm._v(" "+_vm._s(_vm.yearDetails.message)+" ")]):_vm._e(),(typeof _vm.externalProblem === 'string' && _vm.externalProblem.length >= 1)?_c('div',{style:({ padding: '10px 0' })},[_vm._v(" "+_vm._s(_vm.externalProblem)+" ")]):_vm._e()]):_vm._e()]),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
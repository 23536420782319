export const props = {
  breakpoint: {
    type: String,
    default: ''
  },

  pageChange: {
    type: Function,
    default() {
      return () => {}
    }
  },
  dayTapped: {
    type: Function,
    default() {
      return () => {}
    }
  },
  eventTapped: {
    type: Function,
    default() {
      return () => {}
    }
  },
  externalProblem: {
    type: String,
    default: ''
  },
  theme: {
    type: Object,
    default() {
      return {
        1: '#FFF',
        2: '#000',
        3: 'rgb(235, 219, 219)',

        // red
        //  error message border
        4: '#f14668',

        // calendar text color
        5: '#000',

        // box-shadow of events
        6: '#EEE'
      }
    }
  },

  ready: {
    type: Function,
    default() {}
  },

  /*


  */
  events: {
    type: Array,
    default() {
      return []
    }
  }
}

/*
	import { getPlace } from '@/utilities/Number/getPlace'
*/
export function getPlace(num) {
  /*
    1st, 2nd, 3rd
  */

  if (typeof num !== 'number') {
    return num
  }

  let suffix = 'th'
  switch (num) {
    case 1:
      suffix = 'st'
      break
    case 2:
      suffix = 'nd'
      break
    case 3:
      suffix = 'rd'
      break
  }

  return num.toString() + suffix
}

export const watch = {
  async events() {
    await this.curtain(true)

    this.determineEvents({
      month: this.month,
      year: this.year
    })

    await this.curtain(false)
  },
  externalProblem() {
    // console.log ("externalProblem", this.externalProblem)
  }
}

import { parseMoment } from '@/utilities/moment/parse'
import { getPlace } from '@/utilities/Number/getPlace'
import { monthNums, monthStrs } from '@/utilities/Date/constants'

import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'

export const methods = {
  getPlace,

  getInfo() {
    return {
      year: this.year,
      month: this.month
    }
  },

  dayTapped_(dayOfMonth) {
    this.dayTapped({
      year: this.year,
      month: this.month,
      dayOfMonth
    })
  },

  //////////////////////////////
  async yearChange() {
    await this.curtain(true)

    const { successful, message } = await this.setYear(this.yearDetails.value)
    //
    this.yearDetails.message = message
    this.yearDetails.successful = successful
    this.$forceUpdate()

    await this.curtain(false)
  },

  async setYear(year) {
    if (typeof year === 'string') {
      year = parseInt(year)
    }

    if (typeof year !== 'number') {
      return {
        successful: false,
        message: `The year entered doesn't seem to be settable`
      }
    }

    const { successful, message } = await this.show({
      month: this.month,
      year
    })
    await this.pageChange({
      month: this.month,
      year
    })

    return {
      successful,
      message
    }
  },
  //////////////////////////////

  //////////////////////////////
  async monthChange() {
    await this.curtain(true)

    const { successful, message } = this.setMonth({
      str: this.monthDetails.value
    })
    //
    this.monthDetails.message = message
    this.monthDetails.successful = successful
    this.$forceUpdate()

    await this.pageChange({
      month: this.month,
      year: this.year
    })

    await this.curtain(false)
  },
  setMonth({ str }) {
    const num = monthStrs[str]

    if (typeof num === 'number' && num >= 1 && num <= 12) {
      return this.show({
        month: num,
        year: this.year
      })
    } else {
      return {
        successful: false,
        message: `The month entered doesn't seem to be settable.`
      }
    }
  },
  //////////////////////////////

  async curtain(show) {
    if (show) {
      this.loading = true
    }
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, this.opacityDelay)
    })

    if (!show) {
      this.loading = false
    }
  },

  async next() {
    await this.curtain(true)

    let month = this.month
    let year = this.year

    if (month === 12) {
      month = 1
      year++
      this.yearDetails.value = year
    } else {
      month++
    }

    this.monthDetails.value = monthNums[month]
    this.$forceUpdate()

    await this.show({
      month,
      year
    })

    await this.pageChange({
      month,
      year
    })

    await this.curtain(false)
  },
  async prev() {
    await this.curtain(true)

    let month = this.month
    let year = this.year

    if (month === 1) {
      month = 12
      year--
      this.yearDetails.value = year
    } else {
      month--
    }

    this.monthDetails.value = monthNums[month]
    this.$forceUpdate()

    await this.show({
      month,
      year
    })

    await this.pageChange({
      month,
      year
    })

    await this.curtain(false)
  },

  async setDisplay({ month, year }) {
    const m = moment({
      months: month - 1,
      years: year
    })

    if (!m._isValid) {
      return {
        m,
        successful: false,
        message: `The calendar does not seem to be able to show that date.`
      }
    }

    this.opacity = 0
    this.month = month
    this.monthStr = monthNums[month]
    this.year = year

    return {
      m,
      successful: true,
      message: ''
    }
  },
  async determineEvents({ month, year }) {
    const m = moment({
      months: month - 1,
      years: year
    })

    const now = moment()

    this.opacity = 0

    const firstDayOfMonth = moment(m).startOf('month')
    // const lastDayOfMonth = moment(m).endOf('month')

    const daysInMonth = m.daysInMonth()
    // const todaysDayOfWeek = wkDaysNums[moment().day() + 1]

    const panels = []
    const first = moment(firstDayOfMonth).day() + 1

    /*
      1 -> Sunday
      2 -> Monday
      ...
      7 -> Saturday
    */
    if (first >= 2 && first <= 7) {
      for (let a = 0; a < first - 1; a++) {
        panels.push({
          kind: 'prev'
        })
      }
    }

    const events = _cloneDeep(this.events)

    for (let a = 1; a <= daysInMonth; a++) {
      const dayDetails = {
        dayOfMonth: a,
        month,
        year
      }

      const thisDay = moment({
        year,
        month: month - 1,
        day: a
      })

      let isPast = thisDay.isBefore(now)
      if (a === now.date() && year === now.year() && month === now.month() + 1) {
        isPast = false
      }

      let dayEvents = []
      for (let b = 0; b < events.length; b++) {
        const event = events[b]

        if (Object.prototype.hasOwnProperty.call(event, 'date')) {
          const m = moment(events[b].date)

          const eventDetails = {
            dayOfMonth: m.date(),
            month: m.month() + 1,
            year: m.year()
          }

          if (
            eventDetails.dayOfMonth === dayDetails.dayOfMonth &&
            eventDetails.month === dayDetails.month &&
            eventDetails.year === dayDetails.year
          ) {
            dayEvents.push(_cloneDeep(event))
            events.splice(b, 1)
            b--
          }
        } else if (
          Object.prototype.hasOwnProperty.call(event, 'startDate') &&
          Object.prototype.hasOwnProperty.call(event, 'endDate')
        ) {
          const s = parseMoment({
            m: events[b].startDate
          })

          const e = parseMoment({
            m: events[b].endDate
          })

          const _thisDay = parseMoment({
            m: thisDay
          })

          const eventDetails = {
            start: {
              dayOfMonth: s.dayOfMonth,
              month: s.month,
              year: s.orbit
            },
            end: {
              dayOfMonth: e.dayOfMonth,
              month: e.month,
              year: e.orbit
            },
            pointer: {
              dayOfMonth: _thisDay.dayOfMonth,
              month: _thisDay.month,
              year: _thisDay.orbit
            }
          }

          const isStart =
            eventDetails.start.dayOfMonth === eventDetails.pointer.dayOfMonth &&
            eventDetails.start.month === eventDetails.pointer.month &&
            eventDetails.start.year === eventDetails.pointer.year

          const isEnd =
            eventDetails.end.dayOfMonth === eventDetails.pointer.dayOfMonth &&
            eventDetails.end.month === eventDetails.pointer.month &&
            eventDetails.end.year === eventDetails.pointer.year

          const isBeforeEnd = moment(thisDay).isBefore(events[b].endDate)
          const isAfterStart = moment(thisDay).isAfter(events[b].startDate)

          let between = false
          if (isAfterStart && isBeforeEnd) {
            between = true
          }

          if (isStart || between || isEnd) {
            dayEvents.push(_cloneDeep(event))

            if (isEnd) {
              events.splice(b, 1)
              b--
            }
          }
        } else {
          console.error(
            `[event "date" was] or [event "startDate" and "endDate" were] not specified `
          )
          console.error(event)
        }
      }

      panels.push({
        kind: 'day',
        dayNum: a,
        events: dayEvents,
        isPast
      })
    }

    this.panels = panels
    this.opacity = 1
  },

  /*
    months [ 1, 12 ]
  */
  async show({ month, year }) {
    const { successful, message } = await this.setDisplay({ month, year })
    if (!successful) {
      return {
        successful: false,
        message
      }
    }

    await this.determineEvents({ month, year })

    await this.ready({
      month: this.month,
      year: this.year
    })

    return {
      successful: true,
      message: ''
    }
  }
}

/////
